/*
 * @Descripttion: 游戏帐号操作API
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-02 01:40:50
 * @LastEditTime: 2021-10-09 22:45:20
 */
const url = 'index/Member/'
import {
    $post
} from "@/utils" 

// 获取指定角色的帐号列表 
export let getGameRoleList=function (params) {
    return $post(url + 'game_role_list',params)
}

//根据id获取出售帐号信息
export let getGameRoleInfo = function (params) {
    return $post(url + 'game_role_info',params)
}

//获取收藏夹
export let getFavorite=function(){
     return $post(url + 'favorite_list')
}
//修改密码
export let changePwd=function(params){
     return $post(url + 'pwd',params)
}