<!--
 * @Descripttion: 收藏夹
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-08 22:43:51
 * @LastEditTime: 2021-09-30 20:53:03
-->
<template>
  <div>
    <div class="flex"
         style="min-height:770px">

      <el-table v-loading="loading"
                :data="GameRoleList"
                :stripe="true"
                border>
                
        <el-table-column prop="game_role_id"
                         label="编号"
                         width="50" />
                         
        <el-table-column prop="title"
                         label="主装备"
                         width="150">
          <template slot-scope="scope">
                         <img v-if="scope.row.status==2" src="~@/assets/sale.png" style="height: 85px;position: absolute;margin-top: -30px;margin-left: 50px;" />
  

            <span style="border: 1px solid #ff0000;color: red;border-radius: 3px;padding: 1px;"
                  v-if="scope.row.is_hot==1">急售</span>

            {{scope.row.title}}
          </template>
        </el-table-column>
        <el-table-column prop="ZH_Type"
                         label="帐号类型"
                         min-width="80"
                         show-overflow-tooltip />
        <el-table-column prop="qufu"
                         label="所在区服"> </el-table-column>
        <el-table-column label="职业"
                         min-width="90">
          <template slot-scope="{row}">
            {{row.zhongzu}}-{{row.zhiye}}
          </template>
        </el-table-column>
        
        <el-table-column prop="price"
                         min-width="100"
                         label="价格(元)">
                         
          <template slot-scope="scope">
            
            <span v-if="scope.row.price < 10000"
                  style="color: black">{{
            scope.row.price | money
          }}</span>
            <span v-else-if="scope.row.price > 1000 && scope.row.price < 100000"
                  style="color: orange">{{ scope.row.price | money }}</span>
            <span v-else
                  style="color: red">{{ scope.row.price | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         min-width="90"
                         align="center"
                         fixed="right">
          <template slot-scope="{ row }">
   <el-button type="text"
                       size="small"
                       @click="goto(row)">查看</el-button>
            <el-button type="text"
                       size="small"
                       @click="deleFavorite(row)">取消收藏</el-button>

          </template>
        </el-table-column>

      </el-table>

    </div>
    <!-- 分页 -->
    <pagination v-show="count > 0"
                :total="count"
                :page.sync="query.page"
                :limit.sync="query.limit"
                @pagination="getFavorite"
                style="padding:10px" />
  </div>
</template>

<script>
import { getFavorite } from "@/api/member";
import Pagination from "@/components/Pagination";
import { deleFavorite } from "@/api/game_role";
import { MessageBox } from "mint-ui";
export default {
  name: "DbgamewebMembetfavorite",

  components: { Pagination },

  directives: {},

  data() {
    return {
      loading: false,
      count: 0,
      GameRoleList: [],
      model: {
        game_role_id: "",
        content: "",
        zh_cid: "",
        qf_cid: "",
        zy_cid: "",
        description: "",
        title: "",
        is_hot: "0",
        price: "",
        contacts: "",
        contact: "",
        status: "0",
        uid: "0",
      },
      query: {
        //查询分页
        page: 1,
        limit: 10,
      },
    };
  },

  mounted() {
      if (this.$store.state.user.memberInfo) {
      // let memberInfo =this.$store.state.user.memberInfo;
      let memberInfo = JSON.parse(this.$store.state.user.memberInfo);
      this.memberInfo = memberInfo;
      this.uid = memberInfo.member_id;
    } else {

         this.$message.warning('请先登录！');
      this.$router.push({ path: "/login" });
    }
    this.getFavorite();
  },

  methods: {
       goto(row) {
   this.$router.push('/GameRoleInfo/'+row.qf_cid+'/'+row.game_role_id); 
    },
    deleFavorite(row) {
      this.loading = true;

      var title = "温馨提示";
      var message =
        '确定要取消收藏<span style="color:red">' + row.title + " </span>吗？";

      if (this._isMobole()) {
        MessageBox.confirm(message, title)
          .then(() => {
            this.loading = true;
            deleFavorite({
              game_role_id: row.game_role_id,
            })
              .then(() => {
                this.loading = false;
                this.getFavorite();
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {});
      } else {
        this.$confirm(message, title, {
          type: "warning",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            this.loading = true;
            deleFavorite({
              game_role_id: row.game_role_id,
            })
              .then(() => {
                this.loading = false;
                this.getFavorite();
              })
              .catch(() => {});
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getFavorite() {
      this.loading = true;
      getFavorite()
        .then((res) => {
          this.GameRoleList = res.data.list;
          this.loading = false;
        })
        .catch(() => {});
    },
    _isMobole() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  filters: {
    //钩子，过滤器
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ".00"; //预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>